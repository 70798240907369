
import { IonApp } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import Menu from './components/Menu.vue';
import ShoppingCart from './components/ShoppingCart.vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    Menu,
    ShoppingCart,
  },
  methods: {
      ...mapActions(['getProducts', 'getCategories']),
  },
  created(){
      this.getCategories();
      this.getProducts();
  }
});
