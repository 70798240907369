import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBWjvZXm9dPT3DuqjPU22Wo5Lf5XcXsVj8",
  authDomain: "novoinox-com.firebaseapp.com",
  databaseURL: "https://novoinox-com.firebaseio.com",
  projectId: "novoinox-com",
  storageBucket: "novoinox-com.appspot.com",
  messagingSenderId: "546177209075",
  appId: "1:546177209075:web:529b7867e5a71812f01d86",
  measurementId: "G-B9MWV159T4"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
firebase.firestore().settings({
  ignoreUndefinedProperties: true,
});
export const auth = firebase.auth();