import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { store } from './store';
import i18n from './i18n'
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/md-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

import { IonicVue } from '@ionic/vue';

// Import Custom CSS
import '../public/assets/css/custom.scss';
import '../public/assets/css/nebula.scss';

// Animated On Scroll
export const appear = {
  beforeMount(element: { style: { visibility: string } }) {
    element.style.visibility = 'hidden';
  },
  updated(element: { style: { visibility: string } }, 
          binding: { value: any; oldValue: any }, 
          node: { transition: { leave: (arg0: any, arg1: () => void) => void; beforeEnter: (arg0: any) => void; enter: (arg0: any) => void } | null }) {
    if (!binding.value === !binding.oldValue 
        || null === node.transition) {
      return;
    }
    if (!binding.value) {
      node.transition.leave(element, () => {
        element.style.visibility = 'hidden';
      });
      return;
    }
    node.transition.beforeEnter(element);
    element.style.visibility = '';
    node.transition.enter(element);
  }
};

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './registerServiceWorker';

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(ToastService)
  .directive('appear', appear);
  
router.isReady().then(() => {
  app.mount('#app');
});