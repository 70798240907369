import { createRouter, createWebHistory } from '@ionic/vue-router';
import { store } from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/Products.vue')
  },
  {
    path: '/products/:id',
    name: 'SingleProduct',
    component: () => import('@/views/SingleProduct.vue')
  },
  {
    path: '/search',
    name: 'Search Results',
    component: () => import('@/views/SearchResults.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/Cart.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('@/views/Checkout.vue')
  },
  {
    path: '/checkout/success/:id',
    name: 'Checkout Success',
    component: () => import('@/views/CheckoutSuccess.vue')
  },
  {
    path: '/checkout/error/:id',
    name: 'Checkout Error',
    component: () => import('@/views/CheckoutError.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: () => import('@/views/AboutUs.vue')
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: () => import('@/views/FAQs.vue')
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('@/views/Sitemap.vue')
  },

  /* Footer Pages */
  {
    path: '/legal',
    name: 'Legal Warning',
    component: () => import('@/views/Legal/LegalWarning.vue')
  },
  {
    path: '/privacity',
    name: 'Privacity',
    component: () => import('@/views/Legal/Privacity.vue')
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: () => import('@/views/Legal/Cookies.vue')
  },
  {
    path: '/returns',
    name: 'Returns',
    component: () => import('@/views/Legal/Returns.vue')
  },
  {
    path: '/purchase-conditions',
    name: 'Purchase Conditions',
    component: () => import('@/views/Legal/PurchaseConditions.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

})


router.beforeEach((to, from, next) => {
  store.dispatch('loadLocalStorage').then(() => {
    if (to.meta.requiresAuth) {
      if (store.getters.isLogin) {
        next();
      } else {
        next('/login');
      }
    } else {
      if(to.path === '/login'){
        if (store.getters.isLogin) {
          next('/dashboard');
        } else {
          next();
        }
      }

      next();
    }
  }, error => {
      console.log(error);
  })         

});

export default router
