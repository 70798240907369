/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import router from '../router/index';
import { auth, db } from '@/firebaseConfig';

export const store = createStore({
  state: {
    products: [] as any[],
    newProducts: [] as any[],
    
    productsOffer: [] as any[],
    product: {
      images: [],
      variations: [],
      price: 0,
      keywords: [],
      name: '',
      ratings: [],
      category: {},
      subcategory: {},
      description: ''
    },
    categories: [] as any[],
    cart: [] as any[],
    orders: [] as any[],
    order: {},
    user: undefined,
  },
  mutations: {
    setUser(state, payload){
      state.user = payload
    },
    setCart(state, payload){
      state.cart = payload
    },

    setProducts(state, payload){
      state.products = payload;
    },

    addProducts(state, payload){
      state.products.push(payload);
    },

    setProduct(state, payload){
      state.product = payload;
    },
    setNewProducts(state, payload){
      state.newProducts = payload;
    },
    setProductsOffer(state){
      state.products.forEach((element: any) => {
        if(element.offer !== undefined){
          state.productsOffer.push(element);
        }
      });

      state.productsOffer = state.productsOffer.sort((a,b) => {return a.discountStart - b.discountStart });
    },

    // Categories
    setCategories(state, payload){
      state.categories = payload;
    },

    // Cart
    addToCart(state, payload){
      state.cart.push(payload);
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    updateItemsCart(state, payload){
      state.cart = payload;
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    deleteFromCart(state, payload){
      state.cart.splice(payload, 1);
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    resetCart(state, payload){
      state.cart = [] as any[];
      localStorage.removeItem('cart');
    },

    // Orders
    setOrders(state, payload){
      state.orders = payload;
    },
    setOrder(state, payload){
      if(state.orders.length){
        state.order = state.orders.find((x: any) => x.id === payload)
      }
    },
  },
  actions: {
    // USER
    async login({commit}, usuario){
      try {
        const res: any = await auth.signInWithEmailAndPassword(usuario.email, usuario.password);
        if(res){
          const user: any = await db.collection('users').doc(res.user.uid).get();
          if(user.exists){
            const usuario: any = user.data();
            usuario.dateLogin = new Date().getTime();
            await db.collection('users').doc(res.user.uid).update(usuario);
            commit('setUser', user.data());
            localStorage.setItem('user', JSON.stringify(user.data()));
            router.go(-1);
          }
        }
      } catch (error: any) {
        throw error.message;
      }
    },
    async register({commit}, usuario){
      try {
        console.log(usuario);
        const res: any = await auth.createUserWithEmailAndPassword(usuario.email, usuario.password);
        if(res){
          console.log(res);
          delete usuario.password;
          usuario.dateRegister = new Date().getTime();
          await db.collection('users').doc(res.user.uid).set(usuario);
          commit('setUser', usuario);
          localStorage.setItem('user', JSON.stringify(usuario));
          router.go(-1);
        }
      } catch (error: any) {
        throw error.message;
      }
    },
    async logout({commit}){
      await auth.signOut();
      commit('setUser', null);
      localStorage.removeItem('user');
      router.push('/login-register');
    },
    async updatePassword({commit}, email){
      await auth.sendPasswordResetEmail(email).then(() => {
        // Password reset email sent!
      })
      .catch((error) => {
        console.log(error.code, error.message);
      });
    },
    async updateUser({commit}, user){
      await db.collection('users').doc(user.uid).update(user);
      commit('setUser', user);
      localStorage.setItem('user', JSON.stringify(user));
    },

      // PRODUCTS
      async getProducts({commit}){
        console.log('translado');
      },

      async getNewProducts({commit}){
        console.log('translado');
      },

      async getProduct({commit}, id){
        console.log('translado');
      },
      async updateProduct({commit}, {data, id}){
        await db.collection('/evolbe-modules/shop/products').doc(id).update(data);
      },
      getProductsByOffers({commit}){
        commit('setProductsOffer');
      },

      // CATEGORIES
      async getCategories({commit}){
        if(!this.state.categories.length){
          let data: any[] = [];
          await db.collection('/evolbe-modules/shop/categories').orderBy('order', 'asc').get().then(res => {
            console.log(res);
            data = [];
            res.forEach(item => {
              const e = {
                id: item.id,
                ...item.data()
              }
              data.push(e);
            })
          })
          .catch(err => {
            console.log(err);
          });
          commit('setCategories', data);
        }
      },

      // CART
      addCart({commit}, product){
          const cart = this.state.cart;
          let editProduct;

          cart.forEach((producto, p) => {
            if(producto.id === product.id){
              let same = true;

              product.variations.forEach((vari, v) => {
                vari.options.forEach((opt, o) => {
                  if((opt.selected || false) !== (producto.variations[v].options[o].selected || false)){
                    same = false;
                  }
                });
              });

              if(same){
                editProduct = p;
              }
            }
          });

          if(editProduct !== undefined){
            cart[editProduct].quantity = cart[editProduct].quantity + product.quantity;
            commit('updateItemsCart', cart);
          } else {
            commit('addToCart', product);
          }
      },
      updateCart({commit}, cart){
        commit('updateItemsCart', cart);
      },
      deleteCart({commit}, pos){
        commit('deleteFromCart', pos);
      },
      cleanCart({commit}){
        commit('resetCart');
      },

      // ORDERS

      async addOrder({commit}, data){
        return await db.collection('/evolbe-modules/shop/orders').add(data);
      },
      async updateOrder({commit}, {data, id}){
        await db.collection('/evolbe-modules/shop/orders').doc(id).update(data);
      },
      async getOrders({commit}, uid){
        if(!this.state.products.length){
          let data: any[] = [];
          await db.collection('/evolbe-modules/shop/orders').where('uid', '==' ,uid).orderBy('dateCreated', 'desc').get().then(res => {
            data = [];
            res.forEach(item => {
              const e = {
                id: item.id,
                ...item.data()
              }
              data.push(e);
            })
          })
          .catch(err => {
            console.log(err);
          });
          commit('setOrders', data);
        }
      },
      getOrder({commit}, id){
        commit('setOrder', id)
      },
      async getOrderByID({commit}, id){
        return await db.collection('/evolbe-modules/shop/orders').doc(id).get();
      },


      // OTHERS
      loadLocalStorage({commit}){
        if(this.state.user === undefined){
          if(localStorage.getItem('user')){
            commit('setUser', JSON.parse(localStorage.getItem('user') || '{}'));
            return JSON.parse(localStorage.getItem('user') || '{}');
          } else if(localStorage.getItem('cart')){
            commit('setCart', JSON.parse(localStorage.getItem('cart') || '[]'));
            return JSON.parse(localStorage.getItem('cart') || '[]');
          } else {
            commit('setUser', null);
            commit('setCart', []);
            return null;
          }
        } else {
          return this.state.user, this.state.cart;
        }
      },
  },
  getters: {
    isLogin(state){
      return !!state.user;
    }
  },
  modules: {},
})
