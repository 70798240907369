<template>
  <ion-menu side="start" menu-id="mainMenu" content-id="main">
    <ion-content>
      <ion-header class="ion-no-border">
        <ion-toolbar color="primary" style="padding: 0.5rem">
          <ion-buttons slot="start">
            <ion-button @click="cerrar">
              <ion-icon :icon="closeOutline" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title>Categorías</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-list lines="full" class="categories-list">
        <div v-for="(category, c) in categories.filter(x => x.type === 'category')" :key="category.id">
            <ion-item button>
              <ion-label @click="openCategory(category)">
                  <strong>{{ category.name }}</strong>
              </ion-label>
              <ion-buttons v-if="(categories.filter(x => x.type === 'subcategory' && x.father && x.father.id === category.id )).length" slot="end">
                <ion-button @click="openList[c] = !openList[c]">
                  <ion-icon :icon="!openList[c] ? chevronForwardOutline : chevronDownOutline" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>

          <ion-list lines="full" style="padding:0" :class="!openList[c] ? 'hidden' : ''">
            <ion-menu-toggle v-for="subcategory in categories.filter(x => x.type === 'subcategory' && x.father && x.father.id === category.id )" :key="subcategory.id">
              <ion-item button @click="$router.push({ path: `/products/`, query: { c: category.id, sc: subcategory.id } })">
                <ion-label>{{ subcategory.name }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </div>

      </ion-list>

    </ion-content>
  </ion-menu>
</template>

<script>
import { IonMenu, IonContent, IonList, IonItem, IonMenuToggle, menuController} from '@ionic/vue';
import { chevronForwardOutline, chevronDownOutline, closeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'Menu',
  components: {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonMenuToggle
  },
  data() {
    return {
      openList: [],
    }
  },
  computed: {
    ...mapState(['categories'])
  },
  methods: {
    cerrar(){
      menuController.close('mainMenu');
    },

    openCategory(category){
      this.$router.push({ path: `/products/`, query: { c: category.id } })
      menuController.close('mainMenu');
    }
  },
  setup() {
    return {
      chevronForwardOutline, chevronDownOutline, closeOutline
    }
  }
});
</script>

<style>
  .hidden{
    display: none;
  }

  .categories-list a{
    color: var(--ion-color-dark) !important;
  }

</style>