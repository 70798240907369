<template>
  <ion-menu side="end" menu-id="cartMenu" content-id="main">
    <ion-content>
      <ion-header class="ion-no-border">
        <ion-toolbar color="primary" style="padding: 0.5rem">
          <ion-title>Carrito</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="cerrar">
              <ion-icon :icon="closeOutline" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-list lines="full" class="categories-list">
        <ion-item v-if="!cart.length">
          <ion-label text-wrap v-if="!cart.length">Aún no hay productos en el carrito.</ion-label>
        </ion-item>
        <ion-item v-for="(item, i) in cart" :key="item.id" class="product-item">
          <img :src="item.images[0]" slot="start" class="thumbnail" :alt="item.name"/>
          <ion-label>
            <strong>{{ item.name }}</strong>
            <p>x{{ item.quantity }} - {{ (item.price * item.quantity).toFixed(2) }}€</p>
          </ion-label>
          <ion-buttons slot="end" style="margin-left: 1rem">
            <ion-button @click="deleteProduct(i)">
              <ion-icon slot="icon-only" :icon="closeCircleOutline"></ion-icon>
            </ion-button>
          </ion-buttons>

        </ion-item>

      </ion-list>
      <ion-footer class="footer-cart">
        <ion-toolbar color="light" style="padding: 1rem">
          <ion-item lines="none" style="margin-bottom: 1rem">
            <h5>Total:</h5>
            <h5 slot="end"><strong>{{ total.toFixed(2) }}€</strong></h5>
          </ion-item>
          <ion-menu-toggle menu="cartMenu">
            <ion-button color="secondary" shape="round" expand="full" @click="$router.push({ path: `/cart` })">
              <ion-icon slot="start" :icon="cartOutline"></ion-icon>
              <ion-label>Abrir Carrito</ion-label>
            </ion-button>
          </ion-menu-toggle>
          <ion-menu-toggle menu="cartMenu">
            <ion-button color="dark" shape="round" fill="outline" expand="full" @click="cleanCart" style="margin-top: 0.5rem">
              <ion-icon slot="start" :icon="trashBinOutline"></ion-icon>
              <ion-label>Vaciar Carrito</ion-label>
            </ion-button>
          </ion-menu-toggle>
        </ion-toolbar>
      </ion-footer>
    </ion-content>
  </ion-menu>
</template>

<script>
import { IonMenu, IonContent, IonList, IonItem, IonMenuToggle, menuController } from '@ionic/vue';
import { cartOutline, closeCircleOutline, trashBinOutline, closeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'ShoppingCart',
  components: {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonMenuToggle
  },
  data() {
    return {
      total: 0,
    }
  },
  computed: {
    ...mapState(['cart'])
  },
  methods: {
    ...mapActions(['deleteCart', 'updateCart', 'cleanCart']),

    cerrar(){
      menuController.close('cartMenu');
    },

    deleteProduct(pos){
      this.deleteCart(pos);
      this.recalculate();
    },

    recalculate(){
      this.total = 0;
      this.cart.forEach(item => {
        this.total = this.total + (item.price * item.quantity);
      });
    }
  },
   beforeMount(){
      this.recalculate()
  },
  mounted() {
      let unsubscribe = null
      unsubscribe = this.$store.subscribe(({ type }) => {
          if (type === 'addToCart' || type === 'updateItemsCart' || type === 'deleteFromCart' || type === 'resetCart') {
            this.recalculate();
          }
      });
  },
  setup() {
    return {
      cartOutline, closeCircleOutline, trashBinOutline, closeOutline
    }
  }
});
</script>

<style>
  .footer-cart{
    position: fixed;
    bottom: 0;
  }

  .thumbnail{
    width: 64px;
    height: 64px;
    object-fit: cover;
    object-position: center;
    margin-right: 1rem;
  }

  .product-item{
    --padding-top: 1rem;
    --padding-bottom: 1rem;
  }
</style>